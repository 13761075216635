import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { ReactComponent as Cross } from '../../../assets/images/cross.svg';
import styles from './ModalWindow.module.scss';

const ModalWindow = ({ children, setElementWindow, setActiveImage }) => {
  const [open, setOpen] = useState(true);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={styles.modal}
          onClick={() => {
            setTimeout(() => {
              if (setElementWindow) {
                setElementWindow();

              }
            }, 350);
            setOpen(false);
            setActiveImage("");
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.35,
            },
          }}
          exit={{
            opacity: 0,
          }}
        >
          <motion.div
            className={styles.content}

            onClick={e => e.stopPropagation()}

            initial={{
              scale: 0.7,
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.35,
              },
            }}
            exit={{
              scale: 0.65,
            }}
          >
            <button type="button" className={styles.button}
              onClick={() => {
                setTimeout(() => {
                  if (setElementWindow) {
                    setElementWindow();
                  }
                }, 350);
                setOpen(false);
                setActiveImage("");
              }}
            >
              <Cross />
            </button>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalWindow;
